import {Container,Row,Col,Accordion,Card,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Pageart from "../components/pageart/pageart"
import AccordionContent from "../components/accordionContent/accordionContent"
import ModalOverlay from "../components/modal/modal.js"

import Apple from "../images/apple.svg"
import RecurrenceChart from "../images/recurrence-chart.png"

const TruthsPage = () => (

  <Layout>

    <SEO title="CV Disease, Heart Attack and Stroke: A Deeper Dive" 
      description="Cardiovascular (CV) disease, including heart attack and stroke, is the number one cause of death, both in the US and globally. Learn more about CV disease: current and emerging treatments, the medical and health costs of CV disease, and CV risk factors."
    />

    <div className="more-truths">

      <Pageart content="CV DISEASE, HEART ATTACK, AND STROKE:" content2="A Deeper Dive"></Pageart>

      <AccordionContent />

      <Container fluid className="page-container">
        <Row>
          <Col className="content-wrapper">

            <div className="text-right">
              <span className="accordion-action-all expand-all">Expand all</span>
            </div>


            <Accordion>

              <Card>
                <Card.Header>
                  <Accordion.Toggle variant="link" id="CURRENT_TREATMENTS_FOR_CV_RISK_INCLUDING_HEART_ATTACK_OR_STROKE" className="accordion-link" eventKey="0"  >
                    <strong>CURRENT TREATMENTS</strong> FOR CV RISK, INCLUDING HEART ATTACK OR STROKE
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>

                    <p>High cholesterol is a key cardiovascular (CV) risk factor.<sup>1</sup> Statins and a newer injectable therapy called PCSK9 inhibitors help lower high cholesterol.<sup>2,3</sup></p>

                    <p>We’ll start with statins, since they’re currently the first-line therapy for cholesterol lowering. Statins have been around since the ‘90s and are proven to help lower LDL-C <span class="no-wrap">(bad cholesterol)</span>.<sup>4</sup></p>

                    <p className="callout">While many people rely on statins to reduce their risk of cardiovascular disease because it's the established standard of care, it's also clear that more needs to be done.<sup>5,6</sup> <strong>Statins don't go far enough toward reducing the risk of a CV event, such as a heart attack or stroke.</strong><sup>7</sup></p>
                    
                    <p>If your doctor decides that your bad cholesterol needs to be even lower <strong>than what can be achieved with statin therapy</strong>, you might be prescribed a PCSK9 inhibitor.<sup>2,3</sup> This type of LDL-C-lowering therapy has been around since 2015, and in 2019, PCSK9 inhibitors were FDA approved to reduce the risk of certain CV events.<sup>8,9</sup></p>

                    <p className="callout callout-divider-above">IF YOU'VE HAD A HEART ATTACK OR STROKE AND WENT TO THE HOSPITAL, IT'S IMPORTANT TO FOLLOW UP WITH YOUR DOCTOR BECAUSE THERE'S MORE YOU CAN DO TO HELP PROTECT YOURSELF FROM ANOTHER.<sup>10</sup></p>

                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>

            <Accordion>

              <Card>
                <Card.Header>
                  <Accordion.Toggle id="WHATS_THE_RISK_OF_A_SECOND_HEART_ATTACK_OR_STROKE" variant="link" eventKey="1" className="accordion-link">
                  WHAT'S THE RISK OF A <strong>SECOND HEART ATTACK OR STROKE?</strong>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>If you’ve suffered a heart attack or stroke, you’re at greater risk for having another one.<sup>11,12</sup></p>

                    <p>In fact, after a heart attack, the recurrence rate of another cardiovascular event or heart procedure is up to 50% within 1 year.<sup>10</sup></p>

                   <img className="full-width" alt="50% recurrence within a year graphic" src={RecurrenceChart} />

                    <p>Don’t ignore the risk. If you or someone you love has heart disease, or had experienced a previous heart attack or stroke, ask your doctor about FDA-approved medications.</p>

                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>




            <Accordion>

              <Card>
                <Card.Header>
                  <Accordion.Toggle id="CV_DISEASE_SUCH_AS_HEART_ATTACK_OR_STROKE_IMPACTS_US_ALL" variant="link" eventKey="1" className="accordion-link">
                  CV DISEASE, SUCH AS HEART ATTACK OR STROKE, <strong>IMPACTS US ALL</strong>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>Cardiovascular (CV) disease, including heart attack and stroke, costs lives—a lot of them. That's why it's so important for you and your loved ones to know the risks and be vigilant. Not properly managing CV health has implications beyond your wallet.</p>
                    <p>That cost to health increases as the untreated disease requires hospitalization and impacts our quality <span className="text-nowrap">of life.</span></p>
                    <p>But there are still monetary costs involved in managing and treating any condition. <br></br><strong>Let’s break down how much we spend in the US on CV disease <span className="text-nowrap">per year<sup className='un-bold'>6</sup>:</span></strong></p>
                    <Row className="bracket">
                      <Col xs={12} sm={6} className="bracket-block data">
                        <h5 className="text-uppercase">medical costs (MEDICAL SERVICES AND FOLLOW-UP CARE)</h5>
                        <p className="value">$318 billion</p>
                        <h5 className="text-uppercase">indirect costs (lost productivity at work <span className="text-nowrap">and home)</span></h5>
                        <p className="value">$237 billion</p>
                      </Col>
                      <Col xs={12} sm={6} className="my-auto bracket-block results">
                        <h5>Grand total:</h5>
                        <p className="value">$555 billion</p>
                      </Col>
                    </Row>

                    <p className="mb-0">
                      By 2035, that shocking price tag is expected to rise to<br/>
                      <span className="loudest text-uppercase">$1.1 trillion<sup className="large">6</sup></span>
                    </p>



                    <p><strong>Although CV disease is expensive for healthcare systems, the cost to families may be catastrophic<sup className='un-bold'>13,14</sup></strong></p>
                    <p>Let’s take a closer look at the latest data from 2015, and the price tag of a heart attack or stroke for families and caregivers per year</p>
                    <Row className="bracket full">
                      <Col xs={12} sm={6} className="bracket-block no-bracket data">
                        <h5 className="text-uppercase">THE TOTAL COST OF INFORMAL CAREGIVING, SUCH AS FAMILY CARE, FOR PATIENTS WITH CV DISEASE IN THE US<sup className='un-bold'>13</sup></h5>
                        <p className="value">~$61 billion</p>
                      </Col>
                      <Col xs={12} sm={6} className="bracket-block no-bracket data">
                        <h5 className="text-uppercase">THE TOTAL COST OF FAMILY CARE FOR PATIENTS WITH STROKE IS MORE THAN HALF OF ALL CV DISEASE FAMILY-CARE COSTS<sup className='un-bold'>13</sup></h5>
                        <p className="value">~$31 billion</p>
                      </Col>
                      
                    </Row>

                    <p className="mb-0">
                    Unless action is taken, by 2035, the shocking price tag of CV DISEASE for US families is expected to rise to<br/>
                      <span className="loudest text-uppercase">$128 BILLION<sup className="large">13</sup></span>
                    </p>
                    <p className="callout p-b-0">On an individual level, the average US family cost of <strong>post-stroke care, per patient, per month</strong> is even more startling<sup>14</sup>: <br/>
                    <span className="loudest text-uppercase">~$1236 per patient, per month</span></p>
                    <p>It is on all of us to sound the alarm about the risk of first and subsequent CV events like stroke.</p>

                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>



            <Accordion>

              <Card>
                <Card.Header>
                  <Accordion.Toggle id="SOUND_THE_ALARM_ABOUT_CV_RISK_INCLUDING_HEART_ATTACK_AND_STROKE" variant="link" eventKey="2" className="accordion-link">
                  SOUND THE <strong>ALARM</strong> ABOUT CV RISK, INCLUDING HEART ATTACK AND STROKE
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>

                    <p>Think cancer is the leading cause of death? Think again.</p>

                    <p className="callout"><strong>Cardiovascular (CV) disease, including heart attack and stroke, HAS BEEN THE NUMBER ONE CAUSE OF DEATH</strong> IN THE US SINCE 1920.<sup>6</sup></p>

                    <p><strong>Cancer comes in second to this silent killer.</strong><sup>15</sup>  Interestingly enough, CV disease still isn’t making headlines. Since the mid-1980s, the number of heart health-related campaigns <span className="text-nowrap">has decreased.</span><sup>16</sup></p>

                    <p>A 2018 study reviewed the <em>New York Times</em> coverage of the top 10 causes <span className="text-nowrap">of death.</span></p>

                    <p className="loud">Of those top causes, <strong>heart disease received just 2.5% of the coverage</strong>, while cancer <span className="text-nowrap">received 13.5%.</span><sup>17</sup></p>
                    <p>That's why it's so important to protect you and your loved ones by sounding the alarm about heart attack and stroke, and taking steps to reduce the risk.</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>

            <Accordion>

              <Card>
                <Card.Header>
                  <Accordion.Toggle variant="link" eventKey="3" id="WHAT_ARE_RISK_FACTORS_FOR_A_CV_EVENT_SUCH_AS_HEART_ATTACK_OR_STROKE" className="accordion-link">
                    <strong>WHAT ARE RISK FACTORS</strong> FOR A CV EVENT, SUCH AS HEART ATTACK OR STROKE?
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>

                    <p>Risk factors for cardiovascular (CV) disease include high cholesterol, high triglycerides, diabetes, and high blood pressure. Other factors that contribute to CV risk are: family history; prior CV events, such as heart attack or stroke; smoking; being overweight/obese; diet and exercise.<sup>18</sup></p>
                    <p>Heart disease is the leading cause of death for women in the US, responsible for nearly as many female as male deaths.<sup>19,20</sup> Yet it's a common misconception that heart disease is more common in men.</p>
                    <p>The greater your risk factors for CV disease, the greater your chance of plaque buildup.<sup>18</sup> And as plaque builds up in your arteries (in medical speak, atherosclerosis), you’re more likely to suffer a life-threatening CV event.<sup>21</sup></p>

                    <p className="loud">Here’s how plaque forms in <span className="text-nowrap">3 stages:</span></p>

                    <Row className="grouped plaque-buildup">
                      <Col xs={12} sm={5}>
                        <p className="multi-color"><span>1</span><span>Plaque buildup starts</span></p>
                        <div className="image d-lg-none"></div>
                        <p>Certain factors put us at risk for plaque buildup throughout the arteries. These risk factors can include, but are not limited to, high cholesterol, high triglycerides, diabetes, and high blood pressure.<sup>21</sup></p>
                        <p>Over time, these risk factors injure the blood vessel lining (in medical speak, endothelial cells), causing inflammation.<sup>22</sup></p>
                      </Col>
                      <Col xs={12} sm={7} className="d-none d-lg-block"><div className="image"></div></Col>
                    </Row>

                    <Row className="grouped plaque-grows">
                      <Col xs={12} sm={8} className="d-none d-lg-block"><div className="image"></div></Col>
                      <Col xs={12} sm={4}>
                        <p className="multi-color"><span>2</span><span>Plaque grows</span></p>
                        <div className="image d-lg-none"></div>
                        <p>Inflammation sends out signals to start the 2nd stage: plaque growth. Plaque grows at different rates and in different arteries in the body for everyone. It’s often a slow, gradual process without symptoms you can feel.<sup>21-23</sup></p>
                      </Col>
                    </Row>


                    <p className="multi-color"><span>3</span><span>Plaque ruptures</span></p>

                    <p>As plaque buildup continues, the risk of suffering a CV event increases. If plaque breaks open and ruptures, then the body will try to repair itself. However, this repair process causes blockages to form.<sup>21</sup></p>

                    <p>When an artery becomes fully blocked, then blood flow is restricted. Blocked blood flow to the heart causes a heart attack. Blocked blood flow to the brain causes a stroke.<sup>24,25</sup></p>


                  </Card.Body>
                </Accordion.Collapse>
              </Card>

            </Accordion>



            <p className="louder pt-5">
              <strong className="black">TALK TO YOUR DOCTOR TODAY</strong> ABOUT SWITCHING TO FDA-APPROVED therapies, AS YOU MAY STILL BE AT RISK OF HAVING A HEART ATTACK OR STROKE.
            </p>
            <p className="text-center">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com",<br/>,<p className="mobile-text-300">You will be directed to another Amarin website to learn about a prescription cardiovascular treatment.</p>]}
                buttontext = "Continue"
                link = "https://vascepa.com/"
                ><span class="btn btn-primary text-decoration-none">LEARN MORE</span></ModalOverlay></p>

              
          </Col>
        </Row>
        <Row>
          <Col>
          <p className='reference-text'>
                <strong>References: 1. </strong>Byrne P, Cullinan J, Smith A, Smith SM. Statins for the primary prevention of cardiovascular disease: an overview of systematic reviews. <em>BMJ Open</em>. 2019;9(4):e023085.
                <strong> 2. </strong>Kolber MR, Nickonchuk T, Turgeon R. Do PCSK9 inhibitors reduce cardiovascular events? <em>Can Fam Physician</em>. 2018;64(9):669.
                <strong> 3. </strong>Rosenson RS, Hegele RA, Fazio S, Cannon CP. The evolving future of PCSK9 inhibitors. <em>J Am Coll Cardiol</em>. 2018;72(3):314-329.
                <strong> 4. </strong>Taylor F, Huffman MD, Macedo AF, et al. Statins for the primary prevention of cardiovascular disease. <em>Cochrane Database Syst Rev</em>. 2013;2013(1):CD004816.
                <strong> 5. </strong>American Heart Association. Cholesterol medications. https://www.heart.org/en/health-topics/cholesterol/prevention-and-treatment-of-high-cholesterol-hyperlipidemia/cholesterol-medications. Accessed March 8, 2022.
                <strong> 6. </strong>American Heart Association. Cardiovascular disease: a costly burden for America. Projections through 2035. https://www.heart.org/-/media/files/about-us/policy-research/fact-sheets/ucm_491543.pdf?la=en. Accessed March 8, 2022.
                <strong> 7. </strong>Ganda OP, Bhatt DL, Mason RP, Miller M, Boden WE. Unmet need for adjunctive dyslipidemia therapy in hypertriglyceridemia management. <em>J Am Coll Cardiol</em>. 2018;72(3):330-343.
                <strong> 8. </strong>Pharmacy Times. FDA approves first PCSK9 inhibitor. https://www.pharmacytimes.com/news/fda-approves-first-pcsk9-inhibitor. Published July 24, 2015. Accessed March 8, 2022.
                <strong> 9. </strong>MedPage Today. Second PCSK9 inhibitor approved for CV prevention. https://www.medpagetoday.com/cardiology/prevention/79498. Accessed March 8, 2022.
                <strong> 10. </strong>Bansilal S, Castellano JM, Fuster V. Global burden of CVD: focus on secondary prevention of cardiovascular disease. <em>Int J Cardiol</em>. 2015;201(suppl 1):S1-S7.
                <strong> 11. </strong>American Heart Association. Life after a heart attack. https://www.heart.org/en/health-topics/heart-attack/life-after-a-heart-attack. Accessed March 8, 2022.
                <strong> 12. </strong>American Stroke Association. Stroke risk factors not within your control. https://www.stroke.org/en/about-stroke/stroke-risk-factors/stroke-risk-factors-not-within-your-control. Accessed March 8, 2022.
                <strong> 13. </strong>Dunbar SB, Khavjou OA, Bakas T, et al. Projected costs of informal caregiving for cardiovascular disease: 2015 to 2035: a policy statement from the American Heart Association. <em>Circulation</em>. 2018;137(19):e558-e577.
                <strong> 14. </strong>Rajsic S, Gothe H, Borba HH, et al. Economic burden of stroke: a systematic review on post-stroke care. <em>Eur J Health Econ</em>. 2019;20(1):107-134.
                <strong> 15. </strong>Murphy SL, Kochanek KD, Xu J, Arias E. Mortality in the United States, 2020. <em>NCHS Data Brief</em>. 2018;427:1-8.
                <strong> 16. </strong>Wakefield MA, Loken B, Hornik RC. Use of mass media campaigns to change health behaviour. <em>Lancet</em>. 2010;376(9748):1261-1271.
                <strong> 17. </strong>Our World in Data. Does the news reflect what we die from? https://ourworldindata.org/does-the-news-reflect-what-we-die-from. Published May 29, 2019. Accessed March 8, 2022.
                <strong> 18. </strong>American Heart Association. Understand your risks to prevent a heart attack. https://www.heart.org/en/health-topics/heart-attack/understand-your-risks-to-prevent-a-heart-attack. Accessed March 8, 2022.
                <strong> 19. </strong>Centers for Disease Control and Prevention. Women and heart disease. https://www.cdc.gov/heartdisease/women.htm. Accessed March 8, 2022.
                <strong> 20. </strong>Centers for Disease Control and Prevention. Heart disease facts. https://www.cdc.gov/heartdisease/facts.htm. Accessed March 8, 2022.
                <strong> 21. </strong>National Institutes of Health. Atherosclerosis. https://www.nhlbi.nih.gov/health-topics/atherosclerosis. Accessed March 8, 2022.
                <strong> 22. </strong>Furie MB, Mitchell RN. Plaque attack: one hundred years of atherosclerosis in <em>The American Journal of Pathology</em>. <em>Am J Pathol</em>. 2012;180(6):2184-2187.
                <strong> 23. </strong>Insull W Jr. The pathology of atherosclerosis: plaque development and plaque responses to medical treatment. <em>Am J Med</em>. 2009;122(1 suppl):S3-S14.
                <strong> 24. </strong>National Institutes of Health. Heart attack. https://www.nhlbi.nih.gov/health-topics/heart-attack. Accessed March 8, 2022.
                <strong> 25. </strong>National Institutes of Health. Stroke. https://www.nhlbi.nih.gov/health-topics/stroke. Accessed March 8, 2022.
              </p>
          </Col>
        </Row>
      </Container>

    </div>

  </Layout>

)

export default TruthsPage
